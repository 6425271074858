// MENU 

const nyselect = document.getElementById('ny-select')
const loselect = document.getElementById('lo-select')
const nymenu = document.getElementById('ny-menu')
const lomenu = document.getElementById('lo-menu')

nyselect.addEventListener('click', (e) => {
	nyselect.classList.toggle('active')
	nyselect.ariaExpanded = nyselect.ariaExpanded !== 'true'
	nymenu.classList.toggle('expanded')
	lomenu.classList.remove('expanded')
	loselect.classList.remove('active')
})

loselect.addEventListener('click', (e) => {
	loselect.classList.toggle('active')
	loselect.ariaExpanded = loselect.ariaExpanded !== 'true'
	lomenu.classList.toggle('expanded')
	nymenu.classList.remove('expanded')
	nyselect.classList.remove('active')
})

// LIGHTBOX CONTENT

const lightbox = document.getElementById('lightbox')
const lightboxDisplay = document.getElementById('content-area')
const menuitems = document.querySelectorAll('.menu-item')
const header = document.getElementById('main-menu')

function lightboxContent(section, loc, e) {
	lightbox.classList.toggle('active')
	lightbox.ariaHidden = lightbox.ariaHidden !== 'true'
	header.classList.toggle('hide')

	let contentEl = document.getElementById(section)
	let content = contentEl.outerHTML

	lightboxDisplay.innerHTML+= content
	lightbox.classList.add(loc)
}

function clearLightbox() {
	// lightbox.classList.toggle('active')
	lightbox.ariaHidden = lightbox.ariaHidden !== 'true'
	header.classList.toggle('hide')	
	setTimeout(() => {
		lightboxDisplay.innerHTML = ''
	}, 1000 )
	menuitems.forEach(function(item) {
		item.classList.remove('active')
	})
	lightbox.classList.value = ''
}

menuitems.forEach(function (item) {
	const section = item.dataset.section
	const loc = item.dataset.loc

	item.addEventListener('click', function(e){
		lightboxContent(section, loc, e)
		this.classList.toggle('active')
	}, false)
}) 

const close = document.getElementById('close-button')

close.addEventListener('click', (e) => {
	clearLightbox()
})